
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        








































.events-schedule__days {
  display: flex;
  display: none; // stylelint-disable
  width: col(14);
  margin: 0 col(1);
  padding-bottom: $spacing * 1.5;
  border-bottom: 1px dashed $c-gray-light;
}

.events-schedule__days__day {
  @extend %fw-bold;

  color: $c-gray-dark;
  font-size: 2.4rem;
  line-height: 1em;
  white-space: nowrap;
  cursor: pointer;
  transition: color 0.2s ease-out;

  &:hover {
    color: $c-green-dark;
  }

  & + & {
    margin-left: $spacing;

    @include mq(s) {
      margin-left: $spacing * 2;
    }
  }

  @include mq(s) {
    font-size: 3.2rem;
  }
}

.events-schedule__list {
  width: col(14);
  margin: 0 col(1);
}

.events-schedule__item {
  & + & {
    margin-top: $spacing * 8.5;
  }
}

.events-schedule__item__day {
  @extend %fw-bold;
  @extend %ff-alt;

  padding-bottom: $spacing * 2;
  color: $c-green-dark;
  font-size: 3.2rem;
  line-height: 1em;
  white-space: nowrap;
  border-bottom: 1px dashed $c-gray-light;
}

.events-schedule__item__sessions {
  margin-top: $spacing * 2.5;
}

.events-schedule__item__session {
  & + & {
    margin-top: $spacing * 2;
    padding-top: $spacing * 2;
    border-top: 1px dashed $c-gray-light;
  }

  &:last-child {
    margin-bottom: $spacing * 2;
    padding-bottom: $spacing * 2;
    border-bottom: 1px dashed $c-gray-light;
  }
}
