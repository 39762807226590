
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        
























































































































































































.events-session {
  display: flex;
  flex-direction: column;

  @include mq($until: s) {
    align-items: flex-start;
  }

  @include mq(s) {
    flex-direction: row;
  }
}

.events-session__hour {
  @extend %ff-alt;

  width: 100%;
  color: $c-green-dark;
  font-size: 1.4rem;
  line-height: 1.2em;

  @include mq($until: s) {
    display: flex;
  }

  @include mq(s) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-end;
    flex-shrink: 0;
    width: 150px;
    padding-right: $spacing * 1.5;
    border-right: 1px solid $c-green-light;
  }
}

.events-session__hour__to {
  @include mq($until: s) {
    margin-left: $spacing / 2;
  }

  @include mq(s) {
    margin-top: $spacing / 2;
  }
}

.events-session__content {
  position: relative;
  flex-grow: 1;
  overflow: hidden;
  width: 100%;
  border: 1px solid $c-gray-light;
  border-radius: 3px;
  transform: rotate(0.001deg);
  will-change: transform;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 6px;
    height: 100%;
    background-color: $c-gray-medium;
  }

  .events-session.break & {
    display: flex;
    justify-content: center;
    align-items: center;
    border: 0;
    border-radius: 0;

    &::before {
      content: none;
    }
  }

  @include mq($until: s) {
    margin-top: $spacing / 2;
  }

  @include mq(s) {
    margin-left: $spacing * 2;
  }
}

.events-session__content__break {
  @extend %text-uppercase;
  @extend %fw-bold;

  color: $c-green-light;
  font-size: 2rem;
  line-height: 1.2em;
}

.events-session__content__header {
  position: relative;
  padding: $spacing * 1.5 $spacing * 4 $spacing * 1.5 $spacing * 2;

  .events-session.is-clickable & {
    cursor: pointer;
  }
}

.events-session__content__header__title {
  @extend %ff-alt;
  @extend %fw-bold;

  color: $c-green-dark;
  font-size: 2rem;
  line-height: 1.5em;
}

.events-session__content__header-inner {
  display: flex;
  align-items: center;
  margin-top: $spacing;
}

.events-session__content__header__category {
  @extend %text-uppercase;
  @extend %ff-alt;
  @extend %fw-bold;

  padding: $spacing / 2 $spacing * 0.75;
  color: $c-white;
  font-size: 1.2rem;
  line-height: 1em;
  white-space: nowrap;
  background-color: $c-session-default;

  .events-session.parallel-session & {
    background-color: $c-session-parralel;
  }

  .events-session.plenary-session & {
    background-color: $c-session-plenary;
  }

  .events-session.networking-activity & {
    background-color: $c-session-networking;
  }

  .events-session.technical-visit-and-tour & {
    color: $c-gray-darker;
    background-color: $c-session-visits;
  }

  .events-session.poster-session & {
    background-color: $c-session-poster;
  }

  .events-session.workshop-session & {
    background-color: $c-session-workshop;
  }
}

.events-session__content__header__category,
.events-session__content__header__duration,
.events-session__content__header__room {
  margin-right: $spacing * 1.5;
}

.events-session__content__header__duration,
.events-session__content__header__room {
  display: flex;
  align-items: center;
}

.events-session__content__header__duration__icon,
.events-session__content__header__room__icon {
  display: block;
  fill: $c-green-dark;
}

.events-session__content__header__duration__icon {
  width: 22px;
  height: 22px;
}

.events-session__content__header__room__icon {
  width: 15px;
  height: 19px;
}

.events-session__content__header__duration__label,
.events-session__content__header__room__label {
  @extend %fw-bold;

  margin-left: $spacing / 4;
  color: $c-green-dark;
  font-size: 1.4rem;
  line-height: 1em;
  white-space: nowrap;
}

.events-session__content__header__room__label {
  @extend %text-uppercase;
}

.events-session__content__header__icon {
  position: absolute;
  top: 37px;
  right: 30px;
  overflow: visible;
  width: 20px;
  height: 20px;
  fill: $c-green-dark;
  transition: fill 0.2s ease-out;

  .events-session.is-open &,
  .events-session__content__header:hover & {
    fill: $c-green-light;
  }
}

.events-session__content__text-inner {
  padding: 0 $spacing * 2 $spacing * 1.5;
  color: $c-green-dark;
}
