
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        













































.speakers-grid {
  @include default-margin;
}

.speakers-grid-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
}

.speakers-grid__header {
  width: col(14);
  margin: 0 col(1);

  @include mq(m) {
    display: flex;
    justify-content: space-between;
  }
}

.speakers-grid__title {
  color: $c-green-dark;

  .home & {
    font-size: 2.4rem;
    line-height: 1.4;

    @include mq(l) {
      font-size: 2.8rem;
    }

    @include mq(xxl) {
      font-size: 3.6rem;
    }
  }
}

.speakers-grid__list {
  @extend %list-nostyle;

  display: flex;
  flex-wrap: wrap;
  width: col(14);
  margin: 0 col(1);

  @include mq(m) {
    margin: 0 col(1);
  }

  // stylelint-disable-next-line
  .speakers-grid__header + & {
    margin-top: $spacing * 2;

    @include mq(m) {
      margin-top: $spacing * 5;
    }
  }
}

.speakers-grid__item {
  width: 100%;

  @include mq($until: xs) {
    & + & {
      margin-top: $spacing * 2;
    }
  }

  @include mq(xs, s) {
    width: calc(50% - #{$spacing * 2 / 2});

    &:nth-child(2n + 2) {
      margin-left: $spacing * 2;
    }

    &:nth-child(n + 3) {
      margin-top: $spacing * 2;
    }
  }

  @include mq(s, l) {
    width: calc(33.33% - #{$spacing * 3 * 2 / 3});

    &:nth-child(3n + 2),
    &:nth-child(3n + 3) {
      margin-left: $spacing * 3;
    }

    &:nth-child(n + 4) {
      margin-top: $spacing * 3;
    }
  }

  @include mq(l) {
    width: calc(25% - #{$spacing * 4 * 3 / 4});

    &:nth-child(4n + 2),
    &:nth-child(4n + 3),
    &:nth-child(4n + 4) {
      margin-left: $spacing * 4;
    }

    &:nth-child(n + 5) {
      margin-top: $spacing * 4;
    }
  }
}

.speakers-grid__all {
  margin-top: $spacing * 3;
  margin-left: col(1);

  .home & {
    margin-right: col(1);
    margin-left: auto;
  }
}
