
          $env: 'production';
          @import '/var/build/src/styles/utils/index';
        












.events-title-inner {
  display: flex;
  justify-content: space-between;
  width: col(14);
  margin: 0 col(1);
}

.events-title__label {
  color: $c-gray-medium;
}
